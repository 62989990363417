/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
/*
import { useState, useContext, useRef } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import LinearProgress from "@mui/material/LinearProgress";
import Icon from "@mui/material/Icon";
import {
  DataGrid,
  useGridApiRef,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { AuthContext } from "./../../context";
*/
import "./modalStyles.css";

const Tables = () => {
  const files = [
    {
      name: "5th_Edition_Core_Rulebooks",
      path: "peterpenthouse.com/Guides/5th_Edition_Core_Rulebooks.pdf",
    },
    { name: "curse-of-strahd.pdf", path: "peterpenthouse.com/Guides/curse-of-strahd-OCR.pdf" },
    { name: "D_D5e_Party_Tracker.pdf", path: "peterpenthouse.com/Guides/D_D5e_Party_Tracker.pdf" },
    {
      name: "D_D_Character_Sheets_2017.pdf",
      path: "peterpenthouse.com/Guides/D_D_Character_Sheets_2017.pdf",
    },
    {
      name: "D_D_Starter_Set_Maps.pdf",
      path: "peterpenthouse.com/Guides/D_D_Starter_Set_Maps.pdf",
    },
    {
      name: "Dungeon_Master Basic Rules",
      path: "peterpenthouse.com/Guides/Dungeon_Master%E2%80%99s_Basic_Rules.pdf",
    },
    {
      name: "Elemental_Evil_Players_Companion",
      path: "peterpenthouse.com/Guides/Elemental_Evil_Players_Companion.pdf",
    },
    { name: "Magic Items by Rarity", path: "peterpenthouse.com/Guides/Magic_Items_by_Rarity.pdf" },
    { name: "Monster Manual.pdf", path: "peterpenthouse.com/Guides/Monster_Manual.pdf" },
    {
      name: "Mordenkainen Tome_of_Foes",
      path: "peterpenthouse.com/Guides/Mordenkainen%27s_Tome_of_Foes.pdf",
    },
    { name: "Players_Handbook", path: "peterpenthouse.com/Guides/Player%27s_Handbook.pdf" },
    { name: "Spell_Lists", path: "peterpenthouse.com/Guides/Spell_Lists.pdf" },
    { name: "SpellJammer", path: "peterpenthouse.com/Guides/SpellJammer.pdf" },
    {
      name: "Sword_Coast_Adventurer%27s_Guide",
      path: "peterpenthouse.com/Guides/Sword_Coast_Adventurer%27s_Guide.pdf",
    },
    {
      name: "Sword_Coast_Adventurer%27s_Guide_Maps",
      path: "peterpenthouse.com/Guides/Sword_Coast_Adventurer%27s_Guide_Maps.pdf",
    },
    {
      name: "Xanathar%27s_Guide_to_Everything_Delux",
      path: "peterpenthouse.com/Guides/Xanathar%27s_Guide_to_Everything_Deluxe.pdf",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <ul>
                {files.map((file) => (
                  <li key={file.name}>
                    <a href={file.path} target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </a>
                    <span style={{ marginLeft: "10px" }}>
                      <a href={file.path} download>
                        (Download)
                      </a>
                    </span>
                  </li>
                ))}
              </ul>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
