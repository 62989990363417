import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ChangelogList from "layouts/Wiki/components/ChangelogList";

//import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";
//import MDButton from "components/MDButton";
//import Icon from "@mui/material/Icon";
//import MDBadge from "components/MDBadge";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Tables = () => {
  const { db } = useContext(AuthContext);

  const [Wikidata, setWiki] = useState("");
  //const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleSave = () => {
    const SaveData = async () => {
      try {
        // Reference to the document you want to update

        const docRef = doc(db, "Wiki", "Ut1uW4hi4pyJrWJmj8SZ");
        const prior = {
          id: "Ut1uW4hi4pyJrWJmj8SZ",
          changelog: [
            {
              version: "1.00.004",
              date: "August 8,2024",
              items: [
                {
                  type: "Added",
                  description:
                    "OCL have been reworked and now have a sumbittal process that includes a customers site and comment fuctions",
                },
                {
                  type: "Added",
                  description: "Burn Rates now display the last updated date",
                },
                {
                  type: "Added",
                  description: "SunBurst Chart added ot help visualize job cost",
                },
                {
                  type: "Added",
                  description:
                    "Equipment Last Reconcile Date is now displayed in the Equipment breakdown of the Job Home",
                },
                {
                  type: "Updated",
                  description:
                    "On invertory Request you can no longer Close Request, Closed status caused confusion vs closing page. Status was deemed no longer nessicary",
                },
                {
                  type: "Added",
                  description:
                    "New Changelog page added, built using react this is the new intended style of the app. Pages will be converted to the new style when time permits",
                },
              ],
            },
            {
              date: "September 24, 2023",
              items: [
                {
                  type: "Added",
                  description:
                    "Stock Length Optimizer Added, Cutlists will now show on the associated Fabpack",
                },
                {
                  description:
                    "RFQ Vendor Notes can now be viewed by hovering over their name in the New RFQ window.",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "RFQ Can now send A Draft Email to the User",
                },
                {
                  type: "Added",
                  description: "Die Information now Contains previous Jobs and Finishes",
                },
                {
                  type: "Added",
                  description:
                    "AWA's are now Functional and Are being beta tested by select field personnel",
                },
                {
                  type: "Added",
                  description:
                    "Color Scheme Updated to dark scheme to allow soft on eyes reading and reduced Mobile device power drain",
                },
                {
                  type: "Added",
                  description: "Fab Pack can now upload Multiple files",
                },
                {
                  description:
                    "PDF's are now viewed in window for most reports, and are visible in mobile versions versus having to download PDF to view",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "PDF merging tool added in the PDF Tools Tab",
                },
                {
                  type: "Added",
                  description: "Save Buttons are now more universally located at the Sub-headbar",
                },
                {
                  description:
                    "Filtering on tables has been revamped to be more consistent and stable",
                  type: "Added",
                },
                {
                  description: "Project detail menu bar chart added to provide a simplified view",
                  type: "Added",
                },
                {
                  description: "Date Range Filtering selection is updated to a single calendar",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "Attachments on POs now have a progress bar for upload progress",
                },
                {
                  description: "Author Now Listed on the RFQ List",
                  type: "Added",
                },
                {
                  description:
                    "Fap Pack Gantt Schedule is now operational, this adds department attributes to Fab Packs",
                  type: "Added",
                },
                {
                  description:
                    "Fab Packs file uploads now show a progress bar for uploading files.",
                  type: "Added",
                },
                {
                  description: "RFQ system Now allow for a draft to be sent to yourself.",
                  type: "Added",
                },
                {
                  description:
                    "You now have the ability to transfer an unfinished fab pack to Another Employee",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "Fab Pack Report now shows the same color coding as the WebPage",
                },
                {
                  description: "Completed button added for fab packs",
                  type: "Added",
                },
                {
                  description: "Export Equipment into CSV Added.",
                  type: "Added",
                },
                {
                  description: "While copying a release the selection box is now a type search.",
                  type: "Updated",
                },
                {
                  type: "Updated",
                  description: "Additional Security Measures to Prevent XSS attacks",
                },
                {
                  type: "Updated",
                  description: "Daily Cost codes are now type search inputs",
                },
                {
                  type: "Updated",
                  description:
                    "Theme changes and Screen sizing adjustments to work better on all devices",
                },
                {
                  description: "Condensed Project Details Menu",
                  type: "Updated",
                },
                {
                  type: "Updated",
                  description: "Equipment Menu Moved To Project Detail Screen and adjusted inputs",
                },
                {
                  type: "Updated",
                  description:
                    "In General pages will not reload after most actions providing a smoother experience",
                },
                {
                  type: "Updated",
                  description:
                    "PO's Turned off feature that will not save line items that have 'New Line' in the QTY.",
                },
                {
                  description:
                    "PO can be saved with vendor as NULL if vendor is NULL it doesn't show up on list. If saved Empty vendor will now default",
                  type: "Bug Fix",
                },
                {
                  description:
                    "Dailys Reworked to be more stable and Daily info and Time are all on one screen.",
                  type: "Bug Fix",
                },
                {
                  type: "Bug Fix",
                  description:
                    "Dailys Miles/Per diem/receipts Issue resolved as a part of the reworking",
                },
                {
                  type: "Bug Fix",
                  description:
                    "Purchase Order Paragraph mode would prevent viewing of text if text was longer than box but still fit on available space on PO",
                },
                {
                  description: "Fab Pack Ladder .xls File can now be replaced once uploaded.",
                  type: "Bug Fix",
                },
                {
                  type: "Bug Fix",
                  description:
                    "Release Approval Emails are not sent back to Author on all releases they are sent to the PO Author.",
                },
                {
                  description: "Fixed RFQ created date does not get added sometimes.",
                  type: "Bug Fix",
                },
                {
                  description:
                    "Corrected some Mobile Devices Displayed two calendars when selecting date",
                  type: "Bug Fix",
                },
                {
                  type: "Bug Fix",
                  description: "Time Sheet bar code displays as 2D barcode instead of QR code.",
                },
                {
                  type: "Bug Fix",
                  description:
                    "Fixed Job Select screen appears for some on login @ project select page landing.",
                },
                {
                  description:
                    "Fixed Loading Screen Doesn't go away after submit of PO if you are the PM.",
                  type: "Bug Fix",
                },
                {
                  description:
                    "Project Detail menu budgets are now more clear where to input budgets",
                  type: "Bug Fix",
                },
                {
                  type: "Hot Fix",
                  description:
                    "Fixed issue with 'Please wait' screen not going away after daily approval.",
                },
                {
                  type: "Hot Fix",
                  description:
                    "Fixed Architect and Customer Request, Notification recipient being incorrect.",
                },
                {
                  description: "Fixed Folder labels out of alignment due to a dependency update.",
                  type: "Hot Fix",
                },
                {
                  type: "Hot Fix",
                  description:
                    "Made adjustments to add legitimacy and prevent spam filtering for RFQ emails.",
                },
                {
                  type: "Hot Fix",
                  description: "Fixed issue that broke hour input for field employees.",
                },
                {
                  type: "Hot Fix",
                  description: "Fixed issue that caused fabpack input boxes to not show options.",
                },
                {
                  description:
                    "Fixed issue with Release Fully approved Notifications not sending to author.",
                  type: "Hot Fix",
                },
                {
                  type: "Hot Fix",
                  description:
                    "Fixed issue with Equipment being deleted if arrival date selected prior to current date.",
                },
              ],
              version: "1.00.003",
            },
            {
              version: "1.00.002",
              items: [
                {
                  description: "New Module = Request For Quotes.",
                  type: "Added",
                },
                {
                  type: "Added",
                  description:
                    "New Module = Job Die tracking and Label Generation. See Die Library Module.",
                },
                {
                  type: "Added",
                  description: "New Module = Tool Box Talk process moved to fully digital.",
                },
                {
                  description:
                    "Ladder/Part Checker updated process, Please use new data extraction file. Selecting Job now required",
                  type: "Updated",
                },
                {
                  type: "Updated",
                  description:
                    "Various Security improvements to defend against SQL Injection attacks, XSS Attacks are unlikely due to restricted access from login.",
                },
                {
                  description: "Various Visual Adjustments and .php warning eliminations.",
                  type: "Updated",
                },
                {
                  description: "Folder request Job Name is now Customizable.",
                  type: "Updated",
                },
                {
                  type: "Fixed",
                  description: "Folder Request Emails Did not send.",
                },
                {
                  type: "Fixed",
                  description:
                    "Significant Site speed optimizations. Most pages saw an average speed increase of 50%",
                },
                {
                  description:
                    "Series Input did not display red if not filled on Purchase Orders/Releases.",
                  type: "Fixed",
                },
                {
                  description: "Release text area width now more closely matches the PDF.",
                  type: "Fixed",
                },
                {
                  type: "Fixed",
                  description: "Bug that did not display Release Line item mode correctly.",
                },
                {
                  type: "Fixed",
                  description: "Bug That prevented the All Job Cost Report from generating.",
                },
                {
                  type: "Fixed",
                  description: "Bug that prevented Uploading of the LadderBOM by ladder.",
                },
                {
                  type: "Fixed",
                  description:
                    "Bug that would Submit the FabPack Form when clicking Upload button.",
                },
                {
                  type: "Fixed",
                  description:
                    "Fab Pack Number Now reserved as soon as you click Add Fabpack, Prevents issues during timeout.",
                },
              ],
              date: "May 23, 2022",
            },
            {
              items: [
                {
                  type: "Updated",
                  description: "Final Approval Emails For Purchase Orders now include Vendor Name",
                },
                {
                  description:
                    "Fab Pack are now 'Manually' sent, after all documents are uploaded a button will appear in the Edit window that will allow you to send the Fab Pack to the Shop, Hourly autosend is now disabled.",
                  type: "Updated",
                },
                {
                  type: "Updated",
                  description: "Project Details Page Visual Refinements.",
                },
                {
                  type: "Updated",
                  description:
                    "Settings Module Allows for Approvers to set other users as Approvers for Days off.",
                },
                {
                  type: "Updated",
                  description: "Visual and Security Refinements made to Employee Editing Modal.",
                },
                {
                  type: "Updated",
                  description: "Visual and Security Refinements made to Fab Pack Editing Modal.",
                },
                {
                  description: "Paramode and Line Item Modes Are now Available in Releases.",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "Releases Now Requires 'Final Approval'.",
                },
                {
                  description:
                    "Fab Pack Details now Require Completion before Completion is selected.",
                  type: "Added",
                },
                {
                  description: "Job Numbers and Job Lists can now be adjusted.",
                  type: "Added",
                },
                {
                  description: "Fab Pack Attachments available via Job Release.",
                  type: "Added",
                },
                {
                  type: "Added",
                  description: "Two factor Authentication is now Enabled via SMS or Email.",
                },
                {
                  description: "Warning Display Fixed with Fab Pack Upload Data File.",
                  type: "Fixed",
                },
                {
                  description: "Series Input Issues Resolved in Upload of Files.",
                  type: "Fixed",
                },
                {
                  type: "Fixed",
                  description: "Barcode Rendering Resolved with Fab Pack.",
                },
                {
                  description: "Locking Job Cost Table resolved.",
                  type: "Fixed",
                },
              ],
              date: "March 18, 2022",
              version: "1.00.001",
            },
          ],
        };
        // Update the document with new data
        await setDoc(docRef, prior, { merge: true });
        // Perform any additional success actions here
        console.log("Document successfully updated!");
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };
    SaveData(); //send for insert
  };
  const fetchWiki = async () => {
    try {
      const docRef = doc(db, "Wiki", "Ut1uW4hi4pyJrWJmj8SZ");
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const inventoryItem = { id: docSnapshot.id, ...docSnapshot.data() };
        setLoading(false);
        return inventoryItem;
      } else {
        console.log("No such document!");
        setLoading(false);
        return null; // Or handle the case where the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching inventory item:", error);
      setLoading(false);
      throw error; // You might want to throw the error or handle it according to your needs
    }
  };
  useEffect(() => {
    const getWikiData = async () => {
      try {
        const wikiData = await fetchWiki();
        setWiki(wikiData);
        console.log(wikiData);
      } finally {
        setLoading(false);
      }
    };

    getWikiData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDButton
                sx={{ display: "none" }}
                onClick={() => {
                  handleSave();
                }}
              >
                Update Change log
              </MDButton>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Project List
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}></Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {loading ? <LinearProgress /> : <ChangelogList Wikidata={Wikidata} />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
