import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { sendEmailVerification } from "firebase/auth";
import { useMaterialUIController, setCurrentUser, AuthContext } from "context";
// Authentication layout components
import { signInWithEmailAndPassword } from "firebase/auth";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import CoverLayout from "layouts/authentication/components/CoverLayout";

function Basic() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const myParam = searchParams.get("Multiple_Session");

  const { signInWithGoogle, AppCurrentUser } = useContext(AuthContext);
  const [controller, dispatch] = useMaterialUIController(); //eslint-disable-line no-unused-vars
  const EmailRef = useRef(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [User, setUser] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordErr, setPasswordErr] = useState(true);
  const [UserErr, setUserErr] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [LoginFail, setLoginFail] = useState([]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  useEffect(() => {
    const storedAuthState = AppCurrentUser;
    if (storedAuthState && storedAuthState.uid) {
      navigate("/dashboard");
    }
    if (myParam) {
      alert(
        "Your session is invalid, only one session can be active at a time, logging in again will log all other sessions out."
      );
    }
    ///ValidateEmail(EmailRef.current.querySelector("input").value);
  }, [AppCurrentUser]);

  const ValidateEmail = (value) => {
    //if valid email then set UserErr(false);
    setUser(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setUserErr(!emailRegex.test(value));
  };
  const ValidatePassword = (value) => {
    //if valid email then set UserErr(false);
    setPassword(value);
    const passwordRegex = /^(?=.*\d{2})(?=.*[!@#$%^&*()_+={}:;"'<>?,./\\|`~])(?=.*[a-zA-Z]).{8,}$/;
    setPasswordErr(!passwordRegex.test(value));
  };
  const handleSignin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, User, Password);

      const user = userCredential.user;
      if (!user.emailVerified) {
        setLoginFail(["Email Not Verified, Check your email", user]);
        setShowSnackbar(true);
        return;
      } ///email verified

      setCurrentUser(dispatch, user);
      navigate("/dashboard");
    } catch (error) {
      setLoginFail(["Fail Login"]);
      setShowSnackbar(true);
      console.error("Error fetching or processing data:", error);
    }
  };
  const closeSnackbar = () => {
    setShowSnackbar(false); // Set state to hide the Snackbar
  };
  const renderContent = () => {
    if (LoginFail[0] === "Email Not Verified, Check your email") {
      return (
        <div>
          {LoginFail[0]}
          <button onClick={() => sendEmailVerification(LoginFail[1])}>Resend Verification</button>
        </div>
      );
    }
    return <div>{LoginFail[0]}</div>;
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                ref={EmailRef}
                label="Email"
                error={UserErr ? true : false}
                success={UserErr ? false : true}
                fullWidth
                onChange={(event) => {
                  ValidateEmail(event.target.value);
                }}
              />
              {UserErr ? (
                <MDBox mb={2} sx={{ fontSize: "8px", color: "red" }}>
                  Input Valid Email{" "}
                </MDBox>
              ) : (
                ""
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                error={PasswordErr ? true : false}
                success={PasswordErr ? false : true}
                onChange={(event) => {
                  ValidatePassword(event.target.value);
                }}
                fullWidth
                onKeyUp={(event) => {
                  if (event.keyCode == 13) {
                    handleSignin();
                  }
                }}
              />
              {PasswordErr ? (
                <MDBox mb={2} sx={{ fontSize: "8px", color: "red" }}>
                  Password Doesnt Match Format 8 total Characters with 2 numbers and 2 Special
                  characters
                </MDBox>
              ) : (
                ""
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch
                checked={rememberMe}
                onChange={() => {
                  handleSetRememberMe();
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                onClick={() => {
                  if (!UserErr && !PasswordErr) {
                    handleSignin();
                  }
                }}
                disabled={!UserErr && !PasswordErr ? false : true}
                color="info"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up with
                </MDTypography>
                <MDButton
                  onClick={() => {
                    signInWithGoogle();
                  }}
                >
                  <GoogleIcon color="inherit" /> Sign In/Up
                </MDButton>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="warning"
        icon="star"
        title="Login Failed"
        content={renderContent()}
        dateTime="now"
        bgWhite
        open={showSnackbar} // Control visibility based on state
        onClose={closeSnackbar}
        close={closeSnackbar}
      />
    </CoverLayout>
  );
}

export default Basic;
