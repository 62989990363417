// @mui material components
import React, { useContext, useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "./style.css";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import { Modal, Button } from "@mui/material";
import { AuthContext } from "context";
import Peter from "../../pages/Hello";
// Authentication layout components

import RYAChicago from "assets/images/RYA Chicago.jpg";
import RYASpain from "assets/images/RYA Spain.jpg";
import RYAAmsterdam from "assets/images/RYA Amsterdam.jpg";
import RYASpring from "assets/images/RYA Spring.jpg";
import RYASummer from "assets/images/RYA Summer.jpg";
import RYAWinter from "assets/images/RYA Winter.jpg";

const imageMap = {
  "RYA Chicago": RYAChicago,
  "RYA Spain": RYASpain,
  "RYA Amsterdam": RYAAmsterdam,
  "RYA Spring": RYASpring,
  "RYA Summer": RYASummer,
  "RYA Winter": RYAWinter,
};

function Dashboard() {
  ///const { auth } = useContext(AuthContext);

  const vids = [
    {
      id: "1",
      title: "RYA Chicago",
      thumbnail: "/Videos/thumbnails/RYA%20Chicago.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Chicago.mp4",
    },
    {
      id: "2",
      title: "RYA Spain",
      thumbnail: "/Videos/thumbnails/RYA%20Spain.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Spain.mp4",
    },
    {
      id: "3",
      title: "RYA Amsterdam",
      thumbnail: "/Videos/thumbnails/RYA%20Amsterdam.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Amsterdam.mp4",
    },
    {
      id: "4",
      title: "RYA Spring",
      thumbnail: "/Videos/thumbnails/RYA%20Spring.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Spring.mp4",
    },
    {
      id: "5",
      title: "RYA Summer",
      thumbnail: "/Videos/thumbnails/RYA%20Summer.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Summer.mp4",
    },
    {
      id: "6",
      title: "RYA Winter",
      thumbnail: "/Videos/thumbnails/RYA%20Winter.jpg",
      url: "https://peterpenthouse.com/Videos/RYA_Winter.mp4",
    },
  ];

  const { AppcurrentUser } = useContext(AuthContext);

  const VideoCard = ({ video }) => {
    const [duration, setDuration] = useState("");
    const handleClick = () => {
      window.open(video.url, "_blank");
    };

    useMemo(() => {
      // Create a video element to load and retrieve the video duration
      const videoElement = document.createElement("video");
      videoElement.src = video.url;
      videoElement.addEventListener("loadedmetadata", () => {
        const minutes = Math.floor(videoElement.duration / 60);
        const seconds = Math.floor(videoElement.duration % 60);
        setDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      });
    }, []);

    return (
      <div className="video-card" onClick={handleClick}>
        <img
          src={imageMap[video.title]} // Use imported image
          alt={video.title}
          className="thumbnail"
          loading="lazy"
        />
        <div className="video-info">
          <h3 className="title">{video.title}</h3>
          <span className="duration">{duration}</span>
        </div>
      </div>
    );
  };

  VideoCard.propTypes = {
    video: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  };
  const VideoList = React.memo(() => {
    return (
      <div className="video-list">
        {vids.map((video) => (
          <VideoCard key={video.id} video={video} />
        ))}
      </div>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {AppcurrentUser?.email}
                </MDTypography>
              </MDBox>
              <MDBox>
                <Peter />
              </MDBox>
              <MDBox pt={3}>
                <VideoList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
