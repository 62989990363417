import { Equipment, Stratagem } from "./classes.js";

export const stratagems = [];

async function logStratagems() {
  const data = [
    {
      name: "APW-1 Anti-Materiel Rifle",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_RIGHT", "C_UP", "C_DOWN"],
      iconPath: "https://helldivers.wiki.gg/images/3/3c/Anti-Materiel_Rifle_Stratagem_Icon.png",
    },
    {
      name: "MD-6 Anti-Personnel Minefield",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_LEFT", "C_UP", "C_RIGHT"],
      iconPath:
        "https://helldivers.wiki.gg/images/b/bb/Anti-Personnel_Minefield_Stratagem_Icon.png",
    },
    {
      name: "ARC-3 Arc Thrower",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_RIGHT", "C_DOWN", "C_UP", "C_LEFT", "C_LEFT"],
      iconPath: "https://helldivers.wiki.gg/images/1/10/Arc_Thrower_Stratagem_Icon.png",
    },
    {
      name: "AC-8 Autocannon",
      isAdvanced: true,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_UP", "C_UP", "C_RIGHT"],
      iconPath: "https://helldivers.wiki.gg/images/e/ef/Autocannon_Stratagem_Icon.png",
    },
    {
      name: "A/AC-8 Autocannon Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_UP", "C_LEFT", "C_UP"],
      iconPath: "https://helldivers.wiki.gg/images/a/a7/Autocannon_Sentry_Stratagem_Icon.png",
    },
    {
      name: "SH-20 Ballistic Shield Backpack",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_DOWN", "C_UP", "C_LEFT"],
      iconPath:
        "https://helldivers.wiki.gg/images/3/37/Ballistic_Shield_Backpack_Stratagem_Icon.png",
    },
    {
      name: "A/M-23 EMS Mortar Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_DOWN", "C_RIGHT"],
      iconPath: "https://helldivers.wiki.gg/images/a/a8/AM-23_EMS_Mortar_Sentry_Stratagem_Icon.png",
    },
    {
      name: "Eagle 110MM Rocket Pods",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_UP", "C_LEFT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (1).png",
    },
    {
      name: "Eagle 500kg Bomb",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_DOWN", "C_DOWN", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (2).png",
    },
    {
      name: "Eagle Airstrike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_DOWN", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (4).png",
    },
    {
      name: "Eagle Cluster Bomb",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_DOWN", "C_DOWN  C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (3).png",
    },
    {
      name: "Eagle Napalm Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_DOWN", "C_UP"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (6).png",
    },
    {
      name: "Eagle Smoke Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_UP", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (5).png",
    },
    {
      name: "Eagle Strafing Run",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_UP", "C_RIGHT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (7).png",
    },
    {
      name: "EAT-17 Expendable Anti-tank",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_DOWN", "C_LEFT", "C_UP", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/SUPPLY (2).png",
    },
    {
      name: "FLAM-40 Flamethrower",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_UP", "C_DOWN", "C_UP"],
      iconPath: "./assets/Strat_icons/CLASS (3).png",
    },
    {
      name: "A/G-16 Gatling Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_LEFT"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (2).png",
    },
    {
      name: "GL-21 Grenade Launcher",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_UP", "C_LEFT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/CLASS (4).png",
    },
    {
      name: "AX/AR-23 Guard Dog",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_UP", "C_LEFT", "C_UP", "C_RIGHT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/SUPPLY (8).png",
    },
    {
      name: "AX/LAS-5 Guard Dog Rover",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_UP", "C_LEFT", "C_UP", "C_RIGHT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/SUPPLY (7).png",
    },
    {
      name: "MG-206 Heavy Machine Gun",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_UP", "C_DOWN", "C_DOWN"],
      iconPath: "https://helldivers.wiki.gg/images/d/d9/Heavy_Machine_Gun_Stratagem_Icon.png",
    },
    {
      name: "E/MG-101 HMG Emplacement",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_LEFT", "C_RIGHT", "C_RIGHT", "C_LEFT"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (11).png",
    },
    {
      name: "MD-I4 Incendiary Mines",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_LEFT", "C_LEFT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (4).png",
    },
    {
      name: "LIFT-850 Jump Pack",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_UP", "C_UP", "C_DOWN", "C_UP"],
      iconPath: "./assets/Strat_icons/SUPPLY (10).png",
    },
    {
      name: "LAS-98 Laser Cannon",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_UP", "C_LEFT"],
      iconPath: "./assets/Strat_icons/SUPPLY (6).png",
    },
    {
      name: "MG-43 Machine Gun",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_UP", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/CLASS (6).png",
    },
    {
      name: "A/MG-43 Machine Gun Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_RIGHT", "C_UP"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (6).png",
    },
    {
      name: "A/M-12 Mortar Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_RIGHT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (8).png",
    },
    {
      name: "Orbital 120MM HE Barrage",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_DOWN", "C_LEFT", "C_RIGHT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (9).png",
    },
    {
      name: "Orbital 380MM HE Barrage",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_DOWN", "C_UP", "C_UP", "C_LEFT", "C_DOWN", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (10).png",
    },
    {
      name: "Orbital Airburst Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (14).png",
    },
    {
      name: "Orbital EMS Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_LEFT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (12).png",
    },
    {
      name: "Orbital Gas Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_DOWN", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (16).png",
    },
    {
      name: "Orbital Gatling Barrage",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_DOWN", "C_LEFT", "C_UP", "C_UP"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (15).png",
    },
    {
      name: "Orbital Laser",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_DOWN", "C_UP", "C_RIGHT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (17).png",
    },
    {
      name: "Orbital Precision Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_UP"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (8).png",
    },
    {
      name: "Orbital Railcannon Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_UP", "C_DOWN", "C_DOWN", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (18).png",
    },
    {
      name: "Orbital Smoke Strike",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_RIGHT", "C_DOWN", "C_UP"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (13).png",
    },
    {
      name: "Orbital Walking Barrage",
      isAdvanced: false,
      type: "Offensive",
      sequence: ["C_RIGHT", "C_DOWN", "C_RIGHT", "C_DOWN", "C_RIGHT", "C_DOWN"],
      iconPath: "./assets/Strat_icons/OFFENSIVE (11).png",
    },
    {
      name: "LAS-99 Quasar Cannon",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_DOWN", "C_UP", "C_LEFT", "C_RIGHT"],
      iconPath: "https://helldivers.wiki.gg/images/8/87/Quasar_Cannon_Stratagem_Icon.png",
    },
    {
      name: "RS-422 Railgun",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_RIGHT", "C_DOWN", "C_UP", "C_LEFT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/CLASS (1).png",
    },
    {
      name: "GR-8 Recoilless Rifle",
      isAdvanced: true,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_RIGHT", "C_RIGHT", "C_LEFT"],
      iconPath: "./assets/Strat_icons/SUPPLY (17).png",
    },
    {
      name: "A/MLS-4X Rocket Sentry",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_RIGHT", "C_LEFT"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (9).png",
    },
    {
      name: "SH-32 Shield Generator Pack",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_UP", "C_LEFT", "C_RIGHT", "C_LEFT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/SUPPLY (5).png",
    },
    {
      name: "FX-12 Shield Generator Relay",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_DOWN", "C_LEFT", "C_RIGHT", "C_LEFT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (3).png",
    },
    {
      name: "FAF-14 SPEAR Launcher",
      isAdvanced: true,
      type: "Support",
      sequence: ["C_DOWN", "C_DOWN", "C_UP", "C_DOWN", "C_DOWN"],
      iconPath: "./assets/Strat_icons/SUPPLY (13).png",
    },
    {
      name: "M-105 Stalwart",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_UP", "C_UP", "C_LEFT"],
      iconPath: "./assets/Strat_icons/CLASS (7).png",
    },
    {
      name: "B-1 Supply Pack",
      isAdvanced: true,
      type: "Backpack",
      sequence: ["C_DOWN", "C_LEFT", "C_DOWN", "C_UP", "C_UP", "C_DOWN"],
      iconPath: "./assets/Strat_icons/SUPPLY (16).png",
    },
    {
      name: "A/ARC-3 Tesla Tower",
      isAdvanced: false,
      type: "Defensive",
      sequence: ["C_DOWN", "C_UP", "C_RIGHT", "C_UP", "C_LEFT", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/DEFENSIVE (10).png",
    },
    {
      name: "EXO-45 Patriot Exosuit",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_LEFT", "C_DOWN", "C_RIGHT", "C_UP", "C_LEFT", "C_DOWN", "C_DOWN"],
      iconPath: "./assets/Strat_icons/SUPPLY (19).png",
    },
    {
      name: "RL-77 Airburst Rocket Launcher",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_UP", "C_UP", "C_LEFT", "C_RIGHT"],
      iconPath:
        "https://helldivers.wiki.gg/images/a/ad/RL-77_Airburst_Rocket_Launcher_Stratagem_Icon.png",
    },
    {
      name: "MLS-4X Commando",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_DOWN", "C_LEFT", "C_UP", "C_DOWN", "C_RIGHT"],
      iconPath: "./assets/Strat_icons/MLS-4X.png",
    },
    {
      name: "EXO-49 Emancipator",
      isAdvanced: false,
      type: "Support",
      sequence: ["C_LEFT", "C_DOWN", "C_RIGHT", "C_UP", "C_LEFT", "C_DOWN", "C_UP"],
      iconPath:
        "https://static.wikia.nocookie.net/helldivers_gamepedia/images/d/d7/EXO-49_Emancipator_Exosuit_Icon.png",
    },
  ];

  const C_LEFT = "\u{1F880}";
  const C_RIGHT = "\u{1F882}";
  const C_UP = "\u{1F881}";
  const C_DOWN = "\u{1F883}";
  const sequenceMap = {
    C_LEFT: C_LEFT,
    C_RIGHT: C_RIGHT,
    C_UP: C_UP,
    C_DOWN: C_DOWN,
  };

  data.forEach((strat) => {
    const sequence = strat.sequence.map((direction) => sequenceMap[direction]);
    stratagems.push(
      new Stratagem(strat.name, strat.isAdvanced, strat.type, sequence.join(" "), strat.iconPath)
    );
  });
}
logStratagems();

export const warbonds = {
  steeled: {
    primary: [
      new Equipment(
        "AR23C-Liberator CONC",
        "Primary",
        "Assault Rifle",
        "steeled",
        "https://helldivers.wiki.gg/images/c/ce/AR-23C_Liberator_Concussive_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-225IE Breaker INC",
        "Primary",
        "Shotgun",
        "steeled",
        "https://helldivers.wiki.gg/images/2/22/SG-225IE_Breaker_Incendiary_Weapon_Icon.png"
      ),
      new Equipment(
        "JAR-5 Dominator",
        "Primary",
        "Explosive",
        "steeled",
        "https://helldivers.wiki.gg/images/d/d5/JAR-5_Dominator_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "P-4 Senator",
        "Secondary",
        "Pistol",
        "steeled",
        "https://helldivers.wiki.gg/images/e/e4/P-4_Senator_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "G-10 Incendiary",
        "Grenade",
        "",
        "steeled",
        "https://helldivers.wiki.gg/images/5/59/G-10_Incendiary_Grenade_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "SA-12 Servo Assisted",
        "Armor",
        "Medium",
        "steeled",
        "https://helldivers.wiki.gg/images/thumb/a/a2/SA-12_Servo_Assisted_Armor_Icon.png/300px-SA-12_Servo_Assisted_Armor_Icon.png"
      ),
      new Equipment(
        "SA-25 Steel Trooper",
        "Armor",
        "Medium",
        "steeled",
        "https://helldivers.wiki.gg/images/thumb/e/e4/SA-25_Steel_Trooper_Armor_Icon.png/300px-SA-25_Steel_Trooper_Armor_Icon.png"
      ),
      new Equipment(
        "SA-32 Dynamo",
        "Armor",
        "Medium",
        "steeled",
        "https://helldivers.wiki.gg/images/thumb/7/7d/SA-32_Dynamo_Armor_Icon.png/300px-SA-32_Dynamo_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Flexible Reinforcement Budget",
        false,
        "booster",
        "steeled",
        "https://helldivers.wiki.gg/images/thumb/c/c1/Flexible_Reinforcement_Budget_Booster_Icon.png/50px-Flexible_Reinforcement_Budget_Booster_Icon.png"
      ),
    ],
  },
  cutting: {
    primary: [
      new Equipment(
        "LAS-16 Sickle",
        "Primary",
        "Energy",
        "cutting",
        "https://helldivers.wiki.gg/images/b/b0/LAS-16_Sickle_Weapon_Icon.png"
      ),
      new Equipment(
        "ARC-12 Blitzer",
        "Primary",
        "Energy",
        "cutting",
        "https://helldivers.wiki.gg/images/c/c9/ARC-12_Blitzer_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-8P Plasma",
        "Primary",
        "Energy",
        "cutting",
        "https://helldivers.wiki.gg/images/0/03/SG-8P_Punisher_Plasma_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "LAS-7 Dagger",
        "Secondary",
        "Pistol Energy",
        "cutting",
        "https://helldivers.wiki.gg/images/3/38/LAS-7_Dagger_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "G-23 Stun",
        "Grenade",
        "",
        "cutting",
        "https://helldivers.wiki.gg/images/3/36/G-23_Stun_Grenade_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "EX-03 Prototype 3",
        "Armor",
        "Medium",
        "cutting",
        "https://helldivers.wiki.gg/images/thumb/7/74/EX-03_Prototype_3_Armor_Icon.png/300px-EX-03_Prototype_3_Armor_Icon.png"
      ),
      new Equipment(
        "EX-16 Prototype 16",
        "Armor",
        "Medium",
        "cutting",
        "https://helldivers.wiki.gg/images/thumb/1/15/EX-16_Prototype_16_Armor_Icon.png/300px-EX-16_Prototype_16_Armor_Icon.png"
      ),
      new Equipment(
        "EX-00 Prototype X",
        "Armor",
        "Medium",
        "cutting",
        "https://helldivers.wiki.gg/images/thumb/f/fd/EX-00_Prototype_X_Armor_Icon.png/300px-EX-00_Prototype_X_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Localization Confusion",
        false,
        "booster",
        "cutting",
        "https://helldivers.wiki.gg/images/thumb/9/9f/Localization_Confusion_Booster_Icon.png/50px-Localization_Confusion_Booster_Icon.png"
      ),
    ],
  },
  democratic: {
    primary: [
      new Equipment(
        "BR-14 Adjudicator",
        "Primary",
        "Energy",
        "democratic",
        "https://helldivers.wiki.gg/images/8/8b/BR-14_Adjudicator_Weapon_Icon.png"
      ),
      new Equipment(
        "R-36 Eruptor",
        "Primary",
        "Energy",
        "democratic",
        "https://helldivers.wiki.gg/images/5/53/R-36_Eruptor_Weapon_Icon.png"
      ),
      new Equipment(
        "CB-9 Exploding Crossbow",
        "Primary",
        "Energy",
        "democratic",
        "https://helldivers.wiki.gg/images/e/e0/CB-9_Exploding_Crossbow_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "GP-31 Grenade Pistol",
        "Secondary",
        "Pistol Explosive",
        "democratic",
        "https://helldivers.wiki.gg/images/4/48/GP-31_Grenade_Pistol_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "G-123 Thermite",
        "Grenade",
        "",
        "democratic",
        "https://helldivers.wiki.gg/images/9/9e/G-123_Thermite_Grenade_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "CE-27 Ground Breaker",
        "Armor",
        "Medium",
        "democratic",
        "https://helldivers.wiki.gg/images/thumb/9/95/CE-27_Ground_Breaker_Armor_Icon.png/300px-CE-27_Ground_Breaker_Armor_Icon.png"
      ),
      new Equipment(
        "CE-07 Demolition Specialist",
        "Armor",
        "Light",
        "democratic",
        "https://helldivers.wiki.gg/images/thumb/f/f7/CE-07_Demolition_Specialist_Armor_Icon.png/300px-CE-07_Demolition_Specialist_Armor_Icon.png"
      ),
      new Equipment(
        "FS-55 Devastator",
        "Armor",
        "Heavy",
        "democratic",
        "https://helldivers.wiki.gg/images/thumb/a/a6/FS-55_Devastator_Armor_Icon.png/300px-FS-55_Devastator_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Expert Extraction Pilot",
        false,
        "booster",
        "democratic",
        "https://helldivers.wiki.gg/images/thumb/e/e0/Expert_Extraction_Pilot_Booster_Icon.png/50px-Expert_Extraction_Pilot_Booster_Icon.png"
      ),
    ],
  },
  polar: {
    primary: [
      new Equipment(
        "AR-61 Tenderizer",
        "Primary",
        "Energy",
        "polar",
        "https://helldivers.wiki.gg/images/0/0c/AR-61_Tenderizer_Weapon_Icon.png"
      ),
      new Equipment(
        "SMG-72 Pummeler",
        "Primary",
        "Energy",
        "polar",
        "https://helldivers.wiki.gg/images/e/e6/SMG-72_Pummeler_Weapon_Icon.png"
      ),
      new Equipment(
        "PLAS-101 Purifier",
        "Primary",
        "Energy",
        "polar",
        "https://helldivers.wiki.gg/images/d/d1/PLAS-101_Purifier_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "P-113 Verdict",
        "Secondary",
        "Pistol Explosive",
        "polar",
        "https://helldivers.wiki.gg/images/3/30/P-113_Verdict_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "G-13 Incindiary Impact",
        "Grenade",
        "",
        "polar",
        "https://helldivers.wiki.gg/images/0/08/G-13_Incendiary_Impact_Grenade_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "CW-4 Artic Ranger",
        "Armor",
        "Light",
        "polar",
        "https://helldivers.wiki.gg/images/thumb/d/d2/CW-4_Arctic_Ranger_Armor_Icon.png/300px-CW-4_Arctic_Ranger_Armor_Icon.png"
      ),
      new Equipment(
        "CW-22 Kodiak",
        "Armor",
        "Heavy",
        "polar",
        "https://helldivers.wiki.gg/images/4/40/CW-22_Kodiak_Armor_Icon.png"
      ),
      new Equipment(
        "CW-36 Winter Warrior",
        "Armor",
        "Medium",
        "polar",
        "https://helldivers.wiki.gg/images/5/5a/CW-36_Winter_Warrior_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Motivational Shocks",
        false,
        "booster",
        "polar",
        "https://helldivers.wiki.gg/images/thumb/6/65/Motivational_Shocks_Booster_Icon.png/130px-Motivational_Shocks_Booster_Icon.png"
      ),
    ],
  },
  commando: {
    primary: [
      new Equipment(
        "AR-23A Liberator Carbine",
        "Primary",
        "Energy",
        "commando",
        "https://helldivers.wiki.gg/images/7/7e/AR-23A_Liberator_Carbine_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "SG-22 Bushwhacker",
        "Secondary",
        "Shotgun",
        "commando",
        "https://helldivers.wiki.gg/images/f/fc/SG-22_Bushwhacker_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "K-2 Throwing Knife",
        "Grenade",
        "",
        "commando",
        "https://helldivers.wiki.gg/images/c/cb/K-2_Throwing_Knife_Throwable_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "PH-9 Predator",
        "Armor",
        "Light",
        "commando",
        "https://helldivers.wiki.gg/images/thumb/0/0d/PH-9_Predator_Armor_Icon.png/300px-PH-9_Predator_Armor_Icon.png"
      ),
      new Equipment(
        "PH-202 Twigsnapper",
        "Armor",
        "Light",
        "commando",
        "https://helldivers.wiki.gg/images/b/b9/PH-202_Twigsnapper_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Experimental Infusion",
        false,
        "booster",
        "commando",
        "https://helldivers.wiki.gg/images/thumb/1/19/Experimental_Infusion_Booster_Icon.png/130px-Experimental_Infusion_Booster_Icon.png"
      ),
    ],
  },
  flame: {
    primary: [
      new Equipment(
        "SG-451 Cookout",
        "Primary",
        "Shotgun",
        "flame",
        "https://helldivers.wiki.gg/images/9/9d/SG-451_Cookout_Weapon_Icon.png"
      ),
      new Equipment(
        "Flam-66 Torcher",
        "Primary",
        "Special",
        "flame",
        "https://helldivers.wiki.gg/images/5/53/FLAM-66_Torcher_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "P-72 Crisper",
        "Secondary",
        "Flame",
        "flame",
        "https://helldivers.wiki.gg/images/9/9d/P-72_Crisper_Weapon_Icon.png"
      ),
    ],
    grenade: [],
    armor: [
      new Equipment(
        "I-09 Heatseeker",
        "Armor",
        "Light",
        "flame",
        "https://helldivers.wiki.gg/images/0/01/I-09_Heatseeker_Armor_Icon.png"
      ),
      new Equipment(
        "I-102 Draconaught",
        "Armor",
        "Medium",
        "flame",
        "https://helldivers.wiki.gg/images/c/c2/I-102_Draconaught_Armor_Icon.png"
      ),
    ],
    booster: [
      new Stratagem(
        "Firebomb Hellpods",
        false,
        "booster",
        "flame",
        "https://helldivers.wiki.gg/images/thumb/1/19/Experimental_Infusion_Booster_Icon.png/130px-Experimental_Infusion_Booster_Icon.png"
      ),
    ],
  },
  super: {
    primary: [
      new Equipment(
        "MP-98 Knight",
        "Primary",
        "SubMachine Gun",
        "super",
        "https://helldivers.wiki.gg/images/2/20/MP-98_Knight_Weapon_Icon.png"
      ),
    ],
    secondary: [],
    grenade: [],
    armor: [
      new Equipment(
        "DP-53 Saviour",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/8/8f/DP-53_Savior_of_the_Free_Armor_Icon.png"
      ),
    ],
    booster: [],
  },
  default: {
    primary: [
      new Equipment(
        "AR23-Liberator",
        "Primary",
        "Assault Rifle",
        "default",
        "https://helldivers.wiki.gg/images/f/f2/AR-23_Liberator_Weapon_Icon.png"
      ),
      new Equipment(
        "AR23P-Liberator PEN",
        "Primary",
        "Assault Rifle",
        "default",
        "https://helldivers.wiki.gg/images/f/fa/AR-23P_Liberator_Penetrator_Weapon_Icon.png"
      ),
      new Equipment(
        "R-63 Diligence",
        "Primary",
        "Marksman Rifle",
        "default",
        "https://helldivers.wiki.gg/images/c/c0/R-63_Diligence_Weapon_Icon.png"
      ),
      new Equipment(
        "R-63CS Diligence CS",
        "Primary",
        "Marksman Rifle",
        "default",
        "https://helldivers.wiki.gg/images/f/f6/R-63CS_Diligence_Counter_Sniper_Weapon_Icon.png"
      ),
      new Equipment(
        "LAS-5 Scythe",
        "Primary",
        "Energy",
        "default",
        "https://helldivers.wiki.gg/images/f/fb/LAS-5_Scythe_Weapon_Icon.png"
      ),
      new Equipment(
        "PLAS-1 Scorcher",
        "Primary",
        "Energy",
        "default",
        "https://helldivers.wiki.gg/images/1/11/PLAS-1_Scorcher_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-8 Punisher",
        "Primary",
        "Shotgun",
        "default",
        "https://helldivers.wiki.gg/images/f/f0/SG-8_Punisher_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-225 Breaker",
        "Primary",
        "Shotgun",
        "default",
        "https://helldivers.wiki.gg/images/7/74/SG-225_Breaker_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-8S Slugger",
        "Primary",
        "Shotgun",
        "default",
        "https://helldivers.wiki.gg/images/b/bd/SG-8S_Slugger_Weapon_Icon.png"
      ),
      new Equipment(
        "SG-225SP Breaker SNP",
        "Primary",
        "Shotgun",
        "default",
        "https://helldivers.wiki.gg/images/b/bc/SG-225SP_Breaker_Spray%26Pray_Weapon_Icon.png"
      ),
      new Equipment(
        "SMG-37 Defender",
        "Primary",
        "SMG",
        "default",
        "https://helldivers.wiki.gg/images/1/10/SMG-37_Defender_Weapon_Icon.png"
      ),
    ],
    secondary: [
      new Equipment(
        "P-2 Peacemaker",
        "Secondary",
        "Pistol",
        "default",
        "https://helldivers.wiki.gg/images/b/bd/P-2_Peacemaker_Weapon_Icon.png"
      ),
      new Equipment(
        "P-19 Redeemer",
        "Secondary",
        "Pistol",
        "default",
        "https://helldivers.wiki.gg/images/4/4d/P-19_Redeemer_Weapon_Icon.png"
      ),
    ],
    grenade: [
      new Equipment(
        "G-3 Smoke",
        "Grenade",
        "",
        "default",
        "https://helldivers.wiki.gg/images/6/64/G-3_Smoke_Grenade_Icon.png"
      ),
      new Equipment(
        "G-6 Frag",
        "Grenade",
        "",
        "default",
        "https://helldivers.wiki.gg/images/b/b7/G-6_Frag_Grenade_Icon.png"
      ),
      new Equipment(
        "G-12 HE",
        "Grenade",
        "",
        "default",
        "https://helldivers.wiki.gg/images/7/7d/G-12_High_Explosive_Grenade_Icon.png"
      ),
      new Equipment(
        "G-16 Impact",
        "Grenade",
        "",
        "default",
        "https://helldivers.wiki.gg/images/6/63/G-16_Impact_Grenade_Icon.png"
      ),
    ],
    armor: [
      new Equipment(
        "B-01 Tactical",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/4/4c/B-01_Tactical_Armor_Icon.png"
      ),
      new Equipment(
        "B-08 Light Gunner",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/4/43/B-08_Light_Gunner_Armor_Icon.png"
      ),
      new Equipment(
        "B-27 Fortified Commando",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/e/e8/B-27_Fortified_Commando_Armor_Icon.png/300px-B-27_Fortified_Commando_Armor_Icon.png"
      ),
      new Equipment(
        "B-24 Enforcer",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/6/68/B-24_Enforcer_Armor_Icon.png/300px-B-24_Enforcer_Armor_Icon.png"
      ),
      new Equipment(
        "CE-35 Trench Engineer",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/1/1b/CE-35_Trench_Engineer_Armor_Icon.png/300px-CE-35_Trench_Engineer_Armor_Icon.png"
      ),
      new Equipment(
        "CE-74 Breaker",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/7/79/CE-74_Breaker_Armor_Icon.png"
      ),
      new Equipment(
        "CE-81 Juggernaut",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/0/00/CE-81_Juggernaut_Armor_Icon.png"
      ),
      new Equipment(
        "CM-09 Bonesnapper",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/e/e1/CM-09_Bonesnapper_Armor_Icon.png"
      ),
      new Equipment(
        "CM-10 Clinician",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/f/fc/CM-10_Clinician_Armor_Icon.png"
      ),
      new Equipment(
        "CM-14 Physician",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/5/5f/CM-14_Physician_Armor_Icon.png/300px-CM-14_Physician_Armor_Icon.png"
      ),
      new Equipment(
        "CM-21 Trench Paramedic",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/1/10/CM-21_Trench_Paramedic_Armor_Icon.png"
      ),
      new Equipment(
        "DP-11 Champion",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/9/90/DP-11_Champion_of_the_People_Armor_Icon.png/300px-DP-11_Champion_of_the_People_Armor_Icon.png"
      ),
      new Equipment(
        "DP-40 Hero",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/f/fc/DP-40_Hero_of_the_Federation_Armor_Icon.png/300px-DP-40_Hero_of_the_Federation_Armor_Icon.png"
      ),

      new Equipment(
        "FS-05 Marksman",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/3/3e/FS-05_Marksman_Armor_Icon.png"
      ),
      new Equipment(
        "FS-11 Executioner",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/c/ca/FS-11_Executioner_Armor_Icon.png"
      ),
      new Equipment(
        "FS-34 Exterminator",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/9/9c/FS-34_Exterminator_Armor_Icon.png"
      ),
      new Equipment(
        "FS-37 Ravager",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/2/29/FS-37_Ravager_Armor_Icon.png"
      ),
      new Equipment(
        "FS-38 Eradicator",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/9/9c/FS-38_Eradicator_Armor_Icon.png"
      ),
      new Equipment(
        "SA-04 Combat Technician",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/9/9f/SA-04_Combat_Technician_Armor_Icon.png/300px-SA-04_Combat_Technician_Armor_Icon.png"
      ),
      new Equipment(
        "SC-30 Trailblazer",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/d/d9/SC-30_Trailblazer_Scout_Armor_Icon.png/300px-SC-30_Trailblazer_Scout_Armor_Icon.png"
      ),
      new Equipment(
        "SC-34 Infiltrator",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/thumb/0/05/SC-34_Infiltrator_Armor_Icon.png/300px-SC-34_Infiltrator_Armor_Icon.png"
      ),
      new Equipment(
        "SC-37 Legionnaire",
        "Armor",
        "Medium",
        "default",
        "https://helldivers.wiki.gg/images/f/f1/SC-37_Legionnaire_Armor_Icon.png"
      ),
    ],

    booster: [
      new Stratagem(
        "Vitality Enhancement",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/a/a1/Vitality_Enhancement_Booster_Icon.png/50px-Vitality_Enhancement_Booster_Icon.png"
      ),
      new Stratagem(
        "Stamina Enhancement",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/b/b3/Stamina_Enhancement_Booster_Icon.png/50px-Stamina_Enhancement_Booster_Icon.png"
      ),
      new Stratagem(
        "Muscle Enhancement",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/5/59/Muscle_Enhancement_Booster_Icon.png/50px-Muscle_Enhancement_Booster_Icon.png"
      ),
      new Stratagem(
        "UAV Recon Booster",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/2/2e/UAV_Recon_Booster_Booster_Icon.png/50px-UAV_Recon_Booster_Booster_Icon.png"
      ),
      new Stratagem(
        "Increased Reinforcement Budget",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/6/69/Increased_Reinforcement_Budget_Booster_Icon.png/50px-Increased_Reinforcement_Budget_Booster_Icon.png"
      ),
      new Stratagem(
        "Hellpod Space Optimization",
        false,
        "booster",
        "default",
        "https://helldivers.wiki.gg/images/thumb/b/bd/Hellpod_Space_Optimization_Booster_Icon.png/50px-Hellpod_Space_Optimization_Booster_Icon.png"
      ),
    ],
  },
};
