// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import "./style.css";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { stratagems, warbonds } from "./src/content.js";
import { changeBackground, getColorForOption } from "./src/misc.js";
import { useEffect } from "react";

function Dashboard() {
  useEffect(() => {
    try {
      document.getElementById("randomizeButton").addEventListener("click", function () {
        let checkboxes = document.querySelectorAll(".stratagem_type:checked");
        const isSingleBackpack = document.getElementById("option_singleback").checked;
        const isSingleSupport = document.getElementById("option_support").checked;
        const isShowCodes = document.getElementById("option_codes").checked;
        const offensiveCheckbox = document.getElementById("option3");
        const defensiveCheckbox = document.getElementById("option4");
        const isPrimary = document.getElementById("primary").checked;
        const isSecondary = document.getElementById("secondary").checked;
        const isGrenade = document.getElementById("grenade").checked;
        const isArmor = document.getElementById("armor").checked;
        const isBooster = document.getElementById("booster").checked;

        const isWarbondDefault = document.getElementById("warbond_default").checked;
        const isWarbondSteeled = document.getElementById("warbond_steeled").checked;
        const isWarbondCutting = document.getElementById("warbond_cutting").checked;
        const isWarbondFlame = document.getElementById("warbond_flame").checked;

        const isWarbondDemocratic = document.getElementById("warbond_democratic").checked;
        const isWarbondPolar = document.getElementById("warbond_polar").checked;
        const isWarbondcommando = document.getElementById("warbond_commando").checked;
        const isSuper = document.getElementById("warbond_super").checked;

        //check at least defensive or offensive box if single backpack and support weapon is activated
        if (isSingleBackpack && isSingleSupport) {
          if (offensiveCheckbox.checked === false && defensiveCheckbox.checked === false) {
            if (Math.random() < 0.5) {
              offensiveCheckbox.checked = true;
              defensiveCheckbox.checked = false;
            } else {
              offensiveCheckbox.checked = false;
              defensiveCheckbox.checked = true;
            }
          }
          checkboxes = document.querySelectorAll(".stratagem_type:checked");
        }

        let stratValues = [];
        checkboxes.forEach(function (checkbox) {
          for (const strat of stratagems) {
            if (strat.category === checkbox.value) {
              stratValues.push(strat);
            }
          }
        });

        let selectedStrats = []; // Declare `selectedStrats` at a higher scope
        if (isSingleBackpack && isSingleSupport) {
          let count_backpack, count_support;

          do {
            // Reset counts for this iteration
            count_backpack = 0;
            count_support = 0;

            // Randomize stratagems
            selectedStrats = randomizeStrategems(stratValues, 4);

            // Count backpack and support stratagems in the randomized selection
            for (const strat of selectedStrats) {
              if (strat.isBackpack) {
                count_backpack += 1;
              }

              if (strat.category === "Support") {
                count_support += 1;
              }
            }
          } while (count_backpack > 1 || count_support > 1);
        } else if (isSingleBackpack) {
          let count_backpack;
          do {
            selectedStrats = randomizeStrategems(stratValues, 4);
            count_backpack = selectedStrats.filter((strat) => strat.isBackpack).length;
          } while (count_backpack > 1);
        } else if (isSingleSupport) {
          let count_support;
          do {
            selectedStrats = randomizeStrategems(stratValues, 4);
            count_support = selectedStrats.filter((strat) => strat.category === "Support").length;
          } while (count_support > 1);
        } else {
          selectedStrats = randomizeStrategems(stratValues, 4);
        }

        let htmlContent = [];
        let countstrat = 0;
        for (const strat of selectedStrats) {
          const color = getColorForOption(strat.category);
          //const name = strat.name;
          let code;
          countstrat++;
          if (isShowCodes) {
            code = `(${strat.codestring})`;
          } else {
            code = "";
          }
          document.getElementById(`${countstrat}-slot`).innerHTML =
            `<span style="color: ${color};">${strat.name} ${code}<img  src="${strat.image}"/></span>`;
        }

        //check warbond selections
        if (
          !isWarbondDefault &&
          !isWarbondSteeled &&
          !isWarbondCutting &&
          !isWarbondDemocratic &&
          !isWarbondPolar &&
          !isWarbondcommando &&
          !isWarbondFlame &&
          !isSuper
        ) {
          document.getElementById("warbond_default").checked = true;
        }

        //push warbond content if selected
        let selectedWarbonds = [];
        if (isWarbondDefault) selectedWarbonds.push("default");
        if (isWarbondSteeled) selectedWarbonds.push("steeled");
        if (isWarbondCutting) selectedWarbonds.push("cutting");
        if (isWarbondDemocratic) selectedWarbonds.push("democratic");
        if (isWarbondPolar) selectedWarbonds.push("polar");
        if (isWarbondcommando) selectedWarbonds.push("commando");
        if (isWarbondFlame) selectedWarbonds.push("flame");
        if (isSuper) selectedWarbonds.push("super");

        var equip_primary = [];
        var equip_secondary = [];
        var equip_grenade = [];
        var equip_armor = [];
        var equip_boosters = [];

        //add pushed content to equipment list
        selectedWarbonds.forEach((warbond) => {
          equip_primary.push(...warbonds[warbond].primary);
          equip_secondary.push(...warbonds[warbond].secondary);
          equip_grenade.push(...warbonds[warbond].grenade);
          equip_armor.push(...warbonds[warbond].armor);
          equip_boosters.push(...warbonds[warbond].booster);
        });
        var selected = "";

        if (isPrimary) {
          selected = randomizeStrategems(equip_primary, 1);
          const name = selected[0].name;
          const ima = selected[0].image;
          document.getElementById("primary-slot").innerHTML =
            `<span style="color: ${"white"};">${name}<img width="150rem" src="${ima}"/></span>`;
        }

        if (isSecondary) {
          selected = randomizeStrategems(equip_secondary, 1);
          const name = selected[0].name;
          const ima = selected[0].image;
          document.getElementById("secondary-slot").innerHTML =
            `<span style="color: ${"white"};">${name}<img width="150rem" src="${ima}"/></span>`;
        }

        if (isGrenade) {
          selected = randomizeStrategems(equip_grenade, 1);
          const name = selected[0].name;
          const ima = selected[0].image;
          document.getElementById("grenade-slot").innerHTML =
            `<span style="color: ${"white"};">${name}<img width="150rem" src="${ima}"/></span>`;
        }

        if (isArmor) {
          selected = randomizeStrategems(equip_armor, 1);
          const name = selected[0].name;
          const ima = selected[0].image;

          const card = document.getElementById("loadoutcard");
          document.getElementById("armor-slot").innerHTML =
            `<span style="color: ${"white"};">${name}<img width="150rem" src="${ima}"/></span>`;

          card.style.background = `url(${ima}) no-repeat center`;
          card.style.backgroundSize = "cover";
        }

        if (isBooster) {
          selected = randomizeStrategems(equip_boosters, 1);
          const name = selected[0].name;
          const ima = selected[0].image;
          document.getElementById("booster-slot").innerHTML =
            `<span style="color: ${"white"};">${name}<img width="150rem" src="${ima}"/></span>`;
        }

        document.getElementById("resultField").innerHTML = htmlContent.join("<br><br>");
        document.getElementById("resultHeader").innerHTML =
          "Helldiver, you have been granted the following Loadout:";
      });
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, []);

  /*
  var WarbondButtonwrapper = document.getElementById("Warbond-Selector");
   
  Object.entries(warbonds).forEach((item,index)=>{
	var warbondbool = document.createElement("input");
	var warbondlabel = document.createElement("lable");
	warbondbool.type = "checkbox";
	warbondbool.id = "warbond_"+item[0];
	warbondbool.value = item[0];
	warbondbool.classList = "warbondinput";
	warbondlabel.textContent = item[0];
	warbondlabel.classList = "warbondlabel";
	warbondlabel.htmlFor = warbondbool.id;
	WarbondButtonwrapper.appendChild(warbondbool);
	WarbondButtonwrapper.appendChild(warbondlabel);
  });
  */
  function randomizeStrategems(strats, n_out) {
    shuffleArray(strats);
    const selectedStrats = strats.slice(0, n_out);
    return selectedStrats;
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  window.onload = changeBackground;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <h1>Welcome to the Helldivers 2 Loadout Randomizer</h1>{" "}
              <div className="updated">
                Last update: 07 August 2024 - added <b>Freedoms Flame Warbond</b>
              </div>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              {" "}
              <section className="stratagem-section">
                <h2 id="h2_sections">Stratagems</h2>
                <div className="checkbox-group">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="option1"
                      className="stratagem_type"
                      value="Support"
                      checked
                    />
                    <label htmlFor="option1">Support Weapon</label>
                    <input
                      type="checkbox"
                      id="option2"
                      className="stratagem_type"
                      value="Backpack"
                      checked
                    />
                    <label htmlFor="option2">Backpack</label>
                  </div>
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="option3"
                      className="stratagem_type"
                      value="Offensive"
                      checked
                    />
                    <label htmlFor="option3">Offensive</label>
                    <input
                      type="checkbox"
                      id="option4"
                      className="stratagem_type"
                      value="Defensive"
                      checked
                    />
                    <label htmlFor="option4">Defensive</label>
                  </div>
                </div>
              </section>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}></MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}></MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}></MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}></MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}></MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}></Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className="container">
                <div className="options-wrapper">
                  <section className="equipment-section">
                    <h2 id="h2_sections">Equipment</h2>
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="primary"
                          className="stratagem_type"
                          value="Primary"
                        />
                        <label htmlFor="primary">Primary</label>
                        <input
                          type="checkbox"
                          id="secondary"
                          className="stratagem_type"
                          value="Secondary"
                        />
                        <label htmlFor="secondary">Secondary</label>
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="grenade"
                            className="stratagem_type"
                            value="Grenade"
                          />
                          <label htmlFor="grenade">Grenade</label>
                          <input
                            type="checkbox"
                            id="armor"
                            className="stratagem_type"
                            value="Armor"
                          />
                          <label htmlFor="armor">Armor</label>
                          <input
                            type="checkbox"
                            id="booster"
                            className="stratagem_type"
                            value="Booster"
                          />
                          <label htmlFor="booster">Booster</label>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="options-wrapper">
                  <section className="options-section">
                    <h2 id="h2_sections">Randomizer</h2>
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="option_support"
                          value="Single Support Weapon"
                          checked
                        />
                        <label htmlFor="option_support">Single Support Weapon</label>
                        <input
                          type="checkbox"
                          id="option_singleback"
                          value="Single Backpack"
                          checked
                        />
                        <label htmlFor="option_singleback">Single Backpack</label>
                        <input type="checkbox" id="option_codes" value="Show Codes" />
                        <label htmlFor="option_codes">Show Codes</label>
                      </div>
                    </div>
                  </section>
                  <section className="additional-section">
                    <h2 id="h2_sections">Warbonds</h2>
                    <div className="checkbox-group">
                      <div className="custom-checkbox" id="Warbond-Selector">
                        <input
                          type="checkbox"
                          id="warbond_default"
                          value="Helldivers Mobilize"
                          checked
                        />
                        <label htmlFor="warbond_default">Helldivers Mobilize</label>
                        <input type="checkbox" id="warbond_steeled" value="Steeled Veterans" />
                        <label htmlFor="warbond_steeled">Steeled Veterans</label>
                        <input type="checkbox" id="warbond_cutting" value="Cutting Edge" />
                        <label htmlFor="warbond_cutting">Cutting Edge</label>
                        <input
                          type="checkbox"
                          id="warbond_democratic"
                          value="Democratic Detonation"
                        />
                        <label htmlFor="warbond_democratic">Democratic Detonation</label>
                        <input type="checkbox" id="warbond_polar" value="Polar Patriot" />
                        <label htmlFor="warbond_polar">Polar Patriot</label>
                        <input type="checkbox" id="warbond_commando" value="Viper Commandos" />
                        <label htmlFor="warbond_commando">Viper Commandos</label>
                        <input type="checkbox" id="warbond_flame" value="Freedoms Flame" />
                        <label htmlFor="warbond_flame">Freedoms Flame</label>
                        <input type="checkbox" id="warbond_super" value="Super Citizen" />
                        <label htmlFor="warbond_super">Super Citizen</label>
                      </div>
                    </div>
                  </section>
                </div>
                <button id="randomizeButton" className="rand">
                  Randomize Loadout
                </button>

                <section id="results-section">
                  <div id="resultHeader">Helldiver, wait for random loadout assignment!</div>
                  <div id="resultField" contentEditable="true"></div>

                  <div
                    id="loadoutcard"
                    style={{
                      height: "50rem",
                      backgroundSize: "cover",
                      gridTemplateRows: "min-content",
                    }}
                    className="container grid grid-cols-5 gap-4"
                  >
                    <div id="primary-slot" className="slot">
                      Primary
                    </div>
                    <div id="secondary-slot" className="slot">
                      Secondary
                    </div>
                    <div id="grenade-slot" className="slot">
                      Grenade
                    </div>
                    <div id="armor-slot" className="slot">
                      Armor
                    </div>
                    <div> </div>

                    <div id="1-slot" className="slot">
                      6
                    </div>
                    <div id="2-slot" className="slot">
                      7
                    </div>
                    <div id="3-slot" className="slot">
                      8
                    </div>
                    <div id="4-slot" className="slot">
                      9
                    </div>
                    <div id="booster-slot" className="slot">
                      Booster
                    </div>
                  </div>
                </section>
              </div>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
