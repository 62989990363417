import { createModel, predictAction } from "../DQN";

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const Dot = ({ isIt, cursorPos, onTag, visionConeAngle, visionConeDistance }) => {
  const [position, setPosition] = useState({
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight,
  });
  const [mode, setMode] = useState("chasing");
  const [lookDirection, setLookDirection] = useState(Math.random() * 2 * Math.PI);
  const [speed, setSpeed] = useState(1);
  const dotRef = useRef(null);
  const [model] = useState(createModel());
  useEffect(() => {
    let animationFrameId;

    const updatePosition = () => {
      const dotRect = dotRef.current.getBoundingClientRect();
      const dotCenter = {
        x: dotRect.left + dotRect.width / 2,
        y: dotRect.top + dotRect.height / 2,
      };
      const dx = cursorPos.x - dotCenter.x;
      const dy = cursorPos.y - dotCenter.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const angleToCursor = Math.atan2(dy, dx);

      const coneAngle = visionConeAngle / 2;
      const isInVisionCone = Math.abs(angleToCursor - lookDirection) < coneAngle;

      if (isInVisionCone && distance < visionConeDistance) {
        onTag();
        setMode("tagged");
        model.summary();
        setSpeed(0);
        return;
      }

      if (!isInVisionCone) {
        setMode("searching"); // Set mode to searching if not in vision cone
      } else {
        setMode("chasing"); // Set mode to chasing if cursor is in vision cone
      }

      const state = [
        isInVisionCone ? 1 : 0, // Vision cone check
        angleToCursor, // Angle to the cursor
        speed, // Current speed
        distance, // Distance to the cursor
        dx, // Cursor X relative to the dot
        dy, // Cursor Y relative to the dot
      ];

      const [predictedDirection, predictedSpeed] = predictAction(model, state);

      setLookDirection(predictedDirection);
      setSpeed(Math.min(predictedSpeed, 5)); // Cap speed to a max value of 5

      // Update the dot's position based on the predicted direction and speed
      const moveX = Math.cos(predictedDirection) * speed;
      const moveY = Math.sin(predictedDirection) * speed;

      setPosition((prevPos) => {
        const newX = prevPos.x + moveX;
        const newY = prevPos.y + moveY;

        return {
          x: Math.max(0, Math.min(window.innerWidth - dotRect.width, newX)),
          y: Math.max(0, Math.min(window.innerHeight - dotRect.height, newY)),
        };
      });

      animationFrameId = requestAnimationFrame(updatePosition);
    };
    animationFrameId = requestAnimationFrame(updatePosition);
    return () => cancelAnimationFrame(animationFrameId);
  }, [cursorPos, visionConeAngle, model, lookDirection, speed, onTag, visionConeDistance]);

  return (
    <div>
      <div
        ref={dotRef}
        style={{
          position: "absolute",
          left: position.x,
          top: position.y,
          width: "30px",
          height: "30px",
          backgroundColor: isIt ? "red" : "blue",
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 1,
        }}
      />
      <div
        style={{
          position: "absolute",
          left: position.x + 15, // Centering the cone
          top: position.y + 15, // Centering the cone
          width: visionConeDistance * 2,
          height: visionConeDistance * 2,
          background: "rgba(255, 0, 0, 0.2)",
          clipPath: `polygon(50% 50%, ${50 + visionConeDistance * Math.cos(lookDirection - visionConeAngle / 2)}% ${50 - visionConeDistance * Math.sin(lookDirection - visionConeAngle / 2)}%, ${50 + visionConeDistance * Math.cos(lookDirection + visionConeAngle / 2)}% ${50 - visionConeDistance * Math.sin(lookDirection + visionConeAngle / 2)})`,
          transform: `rotate(${lookDirection}rad)`,
          transformOrigin: "center center",
          pointerEvents: "none",
          zIndex: 0,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          padding: "5px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          fontSize: "16px",
          zIndex: 2,
        }}
      >
        {`Dot is currently: ${mode}`}
        <br />
        {`Intended Direction: ${lookDirection.toFixed(2)} rad`}
        <br />
        {`Speed: ${speed.toFixed(2)}`}
      </div>
    </div>
  );
};

Dot.propTypes = {
  isIt: PropTypes.bool.isRequired,
  cursorPos: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  onTag: PropTypes.func.isRequired,
  visionConeAngle: PropTypes.number.isRequired,
  visionConeDistance: PropTypes.number.isRequired,
};

export default Dot;
