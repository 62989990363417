import Hd2 from "./layouts/Hd2";
import DND from "./layouts/DND";
import FiveETools from "./layouts/FiveETools";
import GPT from "./layouts/Gpt";
import Billing from "./layouts/billing";
import Portfolio from "./layouts/Portfolio";
import SignIn from "./layouts/authentication/sign-in";
import Wiki from "./layouts/Wiki";
import Home from "./layouts/Home";
import { useEffect } from "react";
/*
import Inventory from "./layouts/inventory";
import Optimize from "./layouts/optimization";
import SignUp from "./layouts/authentication/sign-up";
import ItemDetails from "./layouts/ItemDetails";
import PurchaseOrders from "./layouts/ItemDetails";


*/
import PropTypes from "prop-types";
///import ing2 from "./layouts/testing";
// @mui icons
import Icon from "@mui/material/Icon";
const RedirectToExternalUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  // Return null because we don't want to render anything
  return null;
};

RedirectToExternalUrl.propTypes = {
  url: PropTypes.string, // Ensure children prop is provided and of type node
};
///import { Collapse } from "@mui/material";
const Homepage = "";
const routes = [
  {
    type: "collapse",
    name: "HD2 LoadOut Optimizer",
    key: "Hd2",
    icon: <Icon fontSize="small">Hell Divers2 LoadOut Randomizer</Icon>,
    route: Homepage + "/HD2",
    display: true,
    component: <Hd2 />,
  },
  {
    type: "collapse",
    name: "Home",
    key: "Home",
    icon: <Icon fontSize="small">Home</Icon>,
    route: Homepage + "/dashboard",
    display: true,
    component: <Home />,
  },
  {
    type: "divider",
    display: true,
  },
  {
    type: "collapse",
    name: "Email",
    key: "email",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/Email",
    component: <RedirectToExternalUrl url="/Email" />, // This is where the redirection happens
    display: true, // Ensure display is set to true
  },
  {
    type: "collapse",
    name: "DND",
    key: "dnd",
    icon: <Icon fontSize="small">DND</Icon>,
    route: Homepage + "/DND",
    display: true,
    component: <DND />,
  },
  {
    type: "collapse",
    name: "5eTools",
    key: "5eTools",
    icon: <Icon fontSize="small">5e Tools</Icon>,
    route: Homepage + "/DND/5eTools",
    display: true,
    component: <FiveETools />,
  },
  {
    type: "collapse",
    name: "ChatGpt",
    key: "Gpt",
    icon: <Icon fontSize="small">Gpt</Icon>,
    route: Homepage + "/Gpt",
    display: true,
    component: <GPT model="gpt-4o-mini" />,
  },
  {
    type: "collapse",
    name: "Portfolio",
    key: "Portfolio",
    icon: <Icon fontSize="small">Portfolio</Icon>,
    route: Homepage + "/Portfolio",
    display: true,
    component: <Portfolio />,
  },

  {
    type: "collapse",
    name: "Map",
    key: "Map",
    icon: <Icon fontSize="small">5e Map</Icon>,
    route: Homepage + "/Map",
    display: true,
    component: <RedirectToExternalUrl url="/Map" />,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: Homepage + "/authentication/sign-in",
    display: false,
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "wiki",
    key: "wiki",
    subnav: [
      {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: Homepage + "/dashboard",
        display: true,
        component: <Billing />,
      },
    ],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: Homepage + "/Wiki",
    display: true,
    component: <Wiki />,
  },
];

export default routes;
