import React from "react";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import PropTypes from "prop-types";
import MDBadge from "components/MDBadge";
// Component to render individual changelog items
const ChangelogItem = ({ item }) => {
  var BadgeClass = "";
  switch (item.type) {
    case "Fixed":
      BadgeClass = "error";
      break;
    case "Added":
      BadgeClass = "success";
      break;
    case "Updated":
      BadgeClass = "info";
      break;
    case "Hot Fix":
      BadgeClass = "error";
      break;
    case "Bug Fix":
      BadgeClass = "error";
      break;
    default:
      BadgeClass = "success";
      break;
  }
  return (
    <MDBox sx={{ display: "grid", gridTemplateColumns: "1fr 8fr" }}>
      <MDBadge
        badgeContent={item.type}
        size="sm"
        variant="contained"
        circular
        sx={{ height: "1rem", justifyContent: "right" }}
        color={BadgeClass}
        params=""
      />
      <MDBox sx={{ fontSize: "small" }}>{item.description}</MDBox>
    </MDBox>
  );
};

ChangelogItem.propTypes = {
  item: PropTypes.object.isRequired,
};
// Component to render each changelog entry
const ChangelogEntry = ({ entry }) => {
  return (
    <div>
      <h2>
        {entry.date} - Version {entry.version}
      </h2>
      {entry.items.map((item, index) => (
        <ChangelogItem key={index} item={item} />
      ))}
    </div>
  );
};
ChangelogEntry.propTypes = {
  entry: PropTypes.object.isRequired,
};
// Main component to render the entire changelog
const ChangelogList = ({ Wikidata }) => {
  if (!Wikidata || !Wikidata.changelog) return null;

  return (
    <div>
      {Wikidata.changelog.map((entry, index) => (
        <ChangelogEntry key={index} entry={entry} />
      ))}
    </div>
  );
};

ChangelogList.propTypes = {
  Wikidata: PropTypes.object.isRequired,
};
export default ChangelogList;
