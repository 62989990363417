import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import themeDark from "../assets/theme";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import MDSnackbar from "components/MDSnackbar";
import { ThemeProvider } from "@mui/material/styles";
//import { getStorage } from "firebase/storage";
import { getFirestore, doc, getDoc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
//const analytics = getAnalytics(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();

// Sign out

// Track authentication state

const MaterialUI = createContext();

export const AuthContext = createContext();
// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";
export const AuthProvider = ({ children }) => {
  const [AppcurrentUser, setAppcurrentUser] = useState(null);
  const [Account, setAccount] = useState(null); //eslint-disable-line no-unused-vars
  const [warningSB, setWarningSB] = useState(false);
  const [warningMess, setWarningMess] = useState([]);
  const closeWarningSB = () => setWarningSB(false);
  const renderWarningSB = (
    <ThemeProvider theme={themeDark}>
      <MDSnackbar
        color="warning"
        icon="star"
        title={warningMess[0]}
        content={warningMess[1]}
        dateTime="now"
        open={warningSB}
        onClose={closeWarningSB}
        close={closeWarningSB}
      />
    </ThemeProvider>
  );
  const navigate = useNavigate();
  const location = useLocation();
  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
      throw error;
    }
  };
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Check if user document exists
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      // If user does not exist in Firestore, create a new user document
      if (!userDoc.exists()) {
        throw new Error("No Account");
      } else {
        setAppcurrentUser(user);
        navigate("/dashboard");
      }
    } catch (error) {
      let errorMessage = "An error occurred during sign-in. Please try again.";
      let errorTitle = "Error";
      // Determine the specific error
      if (error.code === "auth/user-disabled") {
        errorMessage = "Your account has been disabled by the administrator.";
        errorTitle = "Account Disabled";
      } else if (error.code === "No Account") {
        errorMessage = "No Account exists with those creditials";
        errorTitle = "No Account";
      } else if (error.code === "auth/popup-closed-by-user") {
        errorMessage = "The popup has been closed before completing the sign-in process.";
        errorTitle = "Popup Closed";
      } else if (error.code === "auth/cancelled-popup-request") {
        errorMessage = "The popup has been closed before completing the sign-in process.";
        errorTitle = "Popup Closed";
      } else if (error.code === "auth/network-request-failed") {
        errorMessage = "Network error occurred. Please check your connection and try again.";
        errorTitle = "Network Error";
      } else if (error.code === "auth/account-exists-with-different-credential") {
        errorMessage =
          "An account already exists with the same email address but different sign-in credentials.";
        errorTitle = "Account Exists";
      }
      setWarningSB(true);
      setWarningMess([errorTitle, errorMessage]);
      //console.error("Error signing in with Google: ", error);
      //throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const currentPath = location.pathname;

      if (user && user.emailVerified) {
        setAppcurrentUser(user);
        ///  fetchAccounts(user);

        if (currentPath == "/authentication/sign-in") {
          navigate("/dashboard");
        }
      } else if (!user) {
        setAppcurrentUser(null);

        const currentPath = location.pathname;
        if (currentPath !== "/authentication/sign-in") {
          localStorage.setItem("redirectTo", currentPath);
          navigate("/authentication/sign-in");
        }
      } else {
        ///email not verified
        let errorMessage = "Check your email for a verification email";
        let errorTitle = "Verify Email";
        setWarningSB(true);
        setWarningMess([errorTitle, errorMessage]);

        navigate("/authentication/sign-in");
      }
    });

    return () => unsubscribe();
  }, [Account]);

  /*
  const fetchAccounts = async (user) => {
    try {
      const AcctRef = collection(db, "Accounts");
      const q = query(AcctRef, where("users", "array-contains", user.uid));
      const querySnapshot = await getDocs(q);
      const AcctData = [];
      querySnapshot.forEach((doc) => {
        AcctData.push({ id: doc.id, ...doc.data() });
      });
      setAccount(AcctData);
    } catch (error) {
      //navigate("/authentication/sign-in"); //change to join group or add group // need group invite code
      console.error("Error fetching Account:", error);
    }
  };*/

  return (
    <AuthContext.Provider
      value={{
        signOutUser,
        signInWithGoogle,
        AppcurrentUser,
        db,
        auth,
      }}
    >
      {children}
      {renderWarningSB}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is provided and of type node
};

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CURRENT_USER": {
      return { ...state, CurrentUser: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const storedDarkState = localStorage.getItem("Dark") === "true";
  const storedColorState = localStorage.getItem("TabColor") || "info";
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: storedColorState,
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    CurrentUser: "",
    darkMode: storedDarkState,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}
// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setCurrentUser = (dispatch, value) => dispatch({ type: "CURRENT_USER", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setCurrentUser,
  setDirection,
  setLayout,
  setDarkMode,
};
