import React, { useState, useEffect, useRef } from "react";
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

const ChatGPT = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [hash, setHash] = useState(window.location.hash.substring(1));
  const codeBoxRef = useRef(null);

  useEffect(() => {
    try {
      setHash(window.location.hash.substring(1));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      setLoading(false);
    }
  }, [hash]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = { role: "user", content: input };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setInput("");

    const url = "https://chatcompletion-pwddzp7vwq-uc.a.run.app";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messages: updatedMessages }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let result = "";

      reader.read().then(function processText({ done, value }) {
        if (done) {
          // Finalize the last message
          return;
        }

        const chunk = decoder.decode(value, { stream: true });
        result += chunk;

        try {
          const data = JSON.parse(result);
          const newContent = data.choices[0].message.content;

          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage && lastMessage.role === "assistant") {
              lastMessage.content = newContent;
              return [...prev.slice(0, -1), lastMessage];
            }
            return [...prev, { role: "assistant", content: newContent }];
          });
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }

        return reader.read().then(processText);
      });
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const isCodeOrHTML = (content) => {
    // Check for HTML or code by detecting HTML tags or common code patterns
    return /<\/?[a-z][\s\S]*>/i.test(content) || /^```/.test(content);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <div>
        <div>
          {messages.map((message, index) => (
            <div key={index} className={message.role === "user" ? "user-message" : "bot-message"}>
              <strong>{message.role === "user" ? "You" : "ChatGPT"}: </strong>
              <div>
                {isCodeOrHTML(message.content) ? (
                  <div className="code-container">
                    <pre ref={codeBoxRef} className="code-box">
                      <code dangerouslySetInnerHTML={{ __html: message.content }} />
                    </pre>
                    <button onClick={() => copyToClipboard(message.content)}>Copy Code</button>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: message.content }} />
                )}
              </div>
            </div>
          ))}
        </div>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default ChatGPT;
