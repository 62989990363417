// src/components/Game.js
import React, { useState, useEffect } from "react";
import Dot from "./Dot";

const Game = () => {
  const [isDotIt, setIsDotIt] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const visionConeAngle = 60; // example angle in degrees
  const visionConeDistance = 150; // example distance in pixels

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  const handleTag = () => {
    setIsDotIt(true);
    setTimeout(() => setIsDotIt(false), 20000); // The dot will be "it" for 2 seconds
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      <Dot
        isIt={isDotIt}
        cursorPos={cursorPos}
        onTag={handleTag}
        visionConeAngle={visionConeAngle}
        visionConeDistance={visionConeDistance}
      />
    </div>
  );
};

export default Game;
