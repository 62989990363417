import React from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
/*
import { AuthContext } from "../../context";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import { serverTimestamp, doc, collection, setDoc } from "firebase/firestore";*/

const Tables = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card></Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
